/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import useItemImages from 'src/hooks/item-images';
import useItemInformation from 'src/hooks/item-information';
import ItemInfoDialogContainer from 'src/components/item-info-dialog';
import ItemCommentDialog from 'src/components/item-comment-dialog';
import Counter from 'src/components/item-counter';
import { Item } from 'src/graphql/generated/operations';
import CartItemAddonList from '../item-addons-list/cart-item';

const defaultProps = {
  showAddons: false,
  showAvatar: false,
  showWarnings: false,
  showDescription: false,
};

const ItemRow = function ({
  item,
  showAddons,
  showAvatar,
  showWarnings,
  showDescription,
}: {
  item: Item;
  showAddons?: boolean;
  showAvatar?: boolean;
  showWarnings?: boolean;
  showDescription?: boolean;
}) {
  const { t } = useTranslation();
  const { price, isCategoryOpenAndActive } = useItemInformation(item);
  const images = useItemImages(item);

  return (
    <>
      <Divider />
      <Paper elevation={0} sx={{ p: 2 }} data-test-id={`item-row-${item.id}}`}>
        <Grid container wrap="nowrap" spacing={2}>
          {showAvatar && (
            <Grid item>
              <img
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: '50px',
                }}
                src={images[240]}
                alt={item.name}
                loading="lazy"
              />
            </Grid>
          )}
          <Grid item xs data-test-id="list-item-contents">
            <Stack
              spacing={1}
              direction="column"
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Stack direction="column" spacing={0}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <b data-test-id="list-item-name">{item.name}</b>
                  <b data-test-id="list-item-price">{price}:-</b>
                </Stack>
                {showDescription && <Typography variant="body2">{item.description}</Typography>}
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                <ItemInfoDialogContainer item={item} mode="icon" />
                <ItemCommentDialog item={item} />
                <Counter item={item} />
              </Stack>
              {showWarnings && (
                <Stack direction="column" spacing={1} alignItems="center">
                  {!isCategoryOpenAndActive && (
                    <Alert icon={false} severity="warning">
                      {t('checkout.item.categoryClosed')}
                    </Alert>
                  )}
                </Stack>
              )}
              {showAddons && <CartItemAddonList item={item} />}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

ItemRow.defaultProps = defaultProps;

export default ItemRow;
