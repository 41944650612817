/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { Item } from 'src/graphql/generated/operations';

interface InfoDialogTextTriggerProps {
  open: () => void;
  item: Item;
}
/**
 * The text trigger has some nasty logic to determine whether to render the "show more" depending on the length of
 * the description on the item in the gallery view. Therefore, when the dialog runs in text mode, it also handles
 * rendering of the description.
 */
const InfoDialogTextTrigger = function ({ open, item }: InfoDialogTextTriggerProps) {
  const { t } = useTranslation();
  const maxDescriptionLength = 60;
  const hasShortenedDescription = item.description.length > maxDescriptionLength;
  const shortDescriptiion = hasShortenedDescription
    ? `${item.description.slice(0, maxDescriptionLength)}...`
    : item.description;
  const hasInfo = !!item.info;
  const showTrigger = hasInfo || hasShortenedDescription;

  return (
    <>
      <span>{shortDescriptiion}</span>
      {showTrigger && (
        <Stack
          direction="row"
          component="span"
          spacing={1}
          alignItems="center"
          data-test-id="item-show-more-cta"
          onClick={() => open()}
        >
          <Typography
            component="span"
            variant="body2"
            data-test-id="item-info-dialog-trigger"
            sx={{ textDecoration: 'underline', whiteSpace: 'pre' }}
          >
            {t('common.infoDialog.open')}
          </Typography>
          <InfoIcon fontSize="inherit" />
        </Stack>
      )}
    </>
  );
};

export default InfoDialogTextTrigger;
