import { Item } from 'src/graphql/generated/operations';

const addSizeToUrl = (url: string, size: string): string => {
  const parts = url.split('/');
  const name = parts.pop();
  if (name) {
    parts.push(size, name);
  }
  return parts.join('/');
};

const getPlaceholder = (): string => `/placeholder.webp`;

export type ImageSize = '240' | '320' | '420' | '640' | '840';

export type Images = {
  [key in ImageSize]: string;
};

// This hook provides image sizes that are arbitrarily defined to fit most mobile screens.
// The sizes can then be used with src/srcSet/sizes attributes on the img element so browsers can
// render images responsively.
const useItemImages = (item: Item): Images => {
  const previewFunc = (size) =>
    item.preview ? addSizeToUrl(item.preview, size) : getPlaceholder();

  return {
    '240': previewFunc('240x240'),
    '320': previewFunc('320x320'),
    '420': previewFunc('420x420'),
    '640': previewFunc('640x640'),
    '840': previewFunc('840x840'),
  };
};

export default useItemImages;
