/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PaiditLink from 'src/components/paidit-link';
import { useTranslation } from 'react-i18next';

const NavbarLinks = function ({
  open,
  onClose,
  anchorEl,
}: {
  open: boolean;
  onClose: () => void;
  anchorEl: Element;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common.navbar' });

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuList sx={{ p: 0 }}>
        <PaiditLink href="/">
          <MenuItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{t('menu')}</span>
          </MenuItem>
        </PaiditLink>

        <Divider />

        <PaiditLink href="/history">
          <MenuItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{t('history')}</span>
            <HistoryIcon sx={{ ml: 1 }} />
          </MenuItem>
        </PaiditLink>

        <Divider />

        {/* eslint-disable-next-line react/forbid-elements */}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://www.paidit.se/jagvilltesta"
          underline="none"
        >
          <MenuItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {t('connect')}
            <OpenInNewIcon sx={{ ml: 1 }} />
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export default NavbarLinks;
