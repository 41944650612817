import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const DialogFormFooter = function ({ submit }: { submit: () => void }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'sticky',
        left: 0,
        bottom: 2,
        right: 0,
        marginBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <Button fullWidth variant="contained" onClick={submit} data-test-id="save-addons-cta">
        {t('common.itemAddonDialog.cta')}
      </Button>
    </Box>
  );
};

export default DialogFormFooter;
