/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaiditLink from 'src/components/paidit-link';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { usePaiditCart } from 'src/hooks/paidit-cart';
import { useGlobalState } from 'src/hooks/global-state';

const NavToolbar = function ({ openMenu }: { openMenu: React.MouseEventHandler }) {
  const [ingestion] = useGlobalState('ingestion');
  const logo = ingestion.logo!;
  const { totalItems } = usePaiditCart();

  return (
    <Toolbar id="back-to-top-anchor">
      <Stack spacing={2} direction="row" sx={{ width: '100%' }} justifyContent="space-between">
        <IconButton size="large" color="inherit" data-test-id="menu-cta" onClick={openMenu}>
          <MenuIcon />
        </IconButton>

        <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ maxHeight: '44px' }} src={logo} alt="logo" />
        </Typography>

        <PaiditLink href="/checkout">
          <IconButton size="large" color="inherit" data-test-id="menu-cta">
            <Badge badgeContent={totalItems} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </PaiditLink>
      </Stack>
    </Toolbar>
  );
};

export default NavToolbar;
