import { Item } from 'src/graphql/generated/operations';
import { usePaiditCart } from '../../hooks/paidit-cart';

/**
 * The item counter keeps track of increasing/decreasing item count, and whether to show the addon dialog or not.
 * It is responsible for whether to change added addons or not depending on whether the item has addons or not.
 * @param item the item for which to use counter logic
 */
const useItemCounter = (item: Item) => {
  const { addItem, removeItem, itemQuantity } = usePaiditCart();

  const increase = () => {
    addItem(item);
  };

  const decrease = () => {
    removeItem(item.id);
  };

  return {
    increase,
    decrease,
    itemQuantity,
  };
};

export default useItemCounter;
