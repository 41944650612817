import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Item } from 'src/graphql/generated/operations';
import { useCategoryIsOpen } from '../../hooks/category-open';
import useItemCounter from './hooks';
import AddonDialog from '../item-addon-dialog';

const Counter = function ({ item }: { item: Item }) {
  const [showAddonDialog, setShowAddonDialog] = useState(false);
  const categoryForItemIsOpen = useCategoryIsOpen(item.category);
  const { increase, decrease, itemQuantity } = useItemCounter(item);
  const itemHasAddons = item.addons?.length > 0;

  /**
   * When increasing the count for an item - if the item has addons, trigger the addons dialog.
   * For removal, any removal of addons is handled by the item counter hook.
   */
  const onIncrease = () => {
    if (itemHasAddons) {
      setShowAddonDialog(true);
    } else {
      increase();
    }
  };

  const quantity = itemQuantity(item.id);

  return (
    <>
      <AddonDialog show={showAddonDialog} close={() => setShowAddonDialog(false)} item={item} />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 1,
          borderRadius: 1,
          borderColor: 'primary.main',
        }}
      >
        <IconButton
          data-test-id="counter-decrease"
          size="small"
          color="primary"
          disabled={quantity === 0}
          onClick={decrease}
        >
          <RemoveIcon />
        </IconButton>
        <Typography data-test-id="counter-count" sx={{ minWidth: '24px', textAlign: 'center' }}>
          {quantity}
        </Typography>
        <IconButton
          data-test-id="counter-increase"
          size="small"
          color="primary"
          disabled={!categoryForItemIsOpen}
          onClick={onIncrease}
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </>
  );
};

export default Counter;
