/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { Item } from 'src/graphql/generated/operations';

interface ItemInfoDialogTriggerProps {
  open: () => void;
  item: Item;
}

const InfoDialogIconTrigger = function ({ open, item }: ItemInfoDialogTriggerProps) {
  const hasInfo = item.info?.length > 0;

  if (!hasInfo) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 1,
      }}
    >
      <IconButton data-test-id="item-info-dialog-trigger" size="small" onClick={() => open()}>
        <InfoIcon color="primary" />
      </IconButton>
    </Paper>
  );
};

export default InfoDialogIconTrigger;
