/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import useComments from 'src/hooks/comments';
import { Item } from 'src/graphql/generated/operations';
import ItemCommentDialog from './dialog';

const ItemComments = function ({ item }: { item: Item }) {
  const { t } = useTranslation('translation', { keyPrefix: 'common.comments.dialog' });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { getComment, setComment, deleteComment } = useComments();
  // The temporary comment is used for performance reasons, as updating the cart
  // metadata on key input is very slow, since a lot of hooks rerender based on it.
  const comment = getComment(item.id);
  const hasComment = !!comment;
  const [temporaryComment, setTemporaryComment] = React.useState(comment);

  const onOpen = () => {
    setDialogOpen(true);
  };

  const onClose = () => {
    setDialogOpen(false);
    setComment(item.id, temporaryComment);
  };

  const onRemove = () => {
    deleteComment(item.id);
    setTemporaryComment('');
    setSnackbarOpen(true);
    setDialogOpen(false);
  };

  return (
    <>
      <Badge badgeContent={hasComment ? '' : undefined} color="secondary">
        <Paper
          elevation={0}
          sx={{
            border: 1,
            borderColor: 'primary.main',
            borderRadius: 1,
          }}
        >
          <IconButton size="small" onClick={onOpen} data-test-id="comment-open-cta">
            <CommentIcon color="primary" />
          </IconButton>
        </Paper>
      </Badge>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={t('removeConfirm')}
      />
      <ItemCommentDialog
        item={item}
        open={dialogOpen}
        onClose={onClose}
        onChange={setTemporaryComment}
        onRemove={onRemove}
        comment={temporaryComment}
      />
    </>
  );
};

export default ItemComments;
