import * as React from 'react';
import Typography from '@mui/material/Typography';
import useItemInformation from 'src/hooks/item-information';
import { Item } from 'src/graphql/generated/operations';
import { useTranslation } from 'react-i18next';

const AddonDialogHeading = function ({ item }: { item: Item }) {
  const { price } = useItemInformation(item);
  const { t } = useTranslation();

  return (
    <Typography variant="body1" align="center" sx={{ mb: 2 }}>
      {t('common.itemAddonDialog.heading', {
        item: item.name,
        price,
        interpolation: { escapeValue: false },
      })}
    </Typography>
  );
};

export default AddonDialogHeading;
