import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { usePaiditCart } from 'src/hooks/paidit-cart';
import { GroupAddonRequestType, Item } from 'src/graphql/generated/operations';
import Transition from '../dialog-transition';
import AddonDialogHeading from './heading';
import AddonDialogForm from './form';
import AddonDialogToolbar from './toolbar';

interface AddonDialogProps {
  show: boolean;
  close: () => void;
  item: Item;
}

const AddonDialog = function ({ show, close, item }: AddonDialogProps) {
  const { addItem } = usePaiditCart();
  const onSubmit = (addons: GroupAddonRequestType[]) => {
    addItem(item, addons);
    close();
  };

  return (
    <Dialog open={show} onClose={close} fullScreen TransitionComponent={Transition}>
      <AddonDialogToolbar close={close} />
      <DialogContent>
        <AddonDialogHeading item={item} />
        <AddonDialogForm item={item} submit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddonDialog;
