/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NavbarMenu from './navbar-menu';
import NavToolbar from './toolbar';

interface NavbarProps {
  children?: React.ReactChild;
}

const Navbar = function ({ children }: NavbarProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar elevation={0}>
        <NavToolbar openMenu={openMenu} />
        {children}
      </AppBar>

      <Toolbar />

      <NavbarMenu anchorEl={anchorEl} open={open} onClose={closeMenu} />
    </>
  );
};

Navbar.defaultProps = {
  children: undefined,
};

export default Navbar;
