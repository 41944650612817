import { Item } from 'src/graphql/generated/operations';
import { useCategoryIsOpen } from './category-open';
import useIngestionType from './ingestion-type';

const useItemInformation = (item: Item) => {
  const ingestion = useIngestionType();
  const isCategoryOpenAndActive = useCategoryIsOpen(item.category);
  const price = item.ingestions.find((i) => i.type === ingestion)?.price;
  const isOutOfStock = item.outOfStock || item.stock === 0;

  return { price, isCategoryOpenAndActive, isOutOfStock };
};

export default useItemInformation;
