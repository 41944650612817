import Link from 'next/link';
import React from 'react';
import useClientKey from 'src/hooks/client-key';
import usePersistentQuery from 'src/hooks/persistent-query';

interface Props {
  children: React.ReactElement;
  href: string;
}

/**
 * A component that preserves/adds query parameters required for the paidit client on navigation.
 */
const PaiditLink = function ({ children, href }: Props) {
  const client = useClientKey();
  const query = usePersistentQuery();
  const url = `/${client}${href}?${query}`;

  return (
    // eslint-disable-next-line react/forbid-elements
    <Link href={`${url}`} passHref>
      {children}
    </Link>
  );
};

export default PaiditLink;
