import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const AddonDialogToolbar = function ({ close }: { close: () => void }) {
  const { t } = useTranslation();

  return (
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <Typography sx={{ flex: 1 }} variant="h6" component="div">
          {t('common.itemAddonDialog.header')}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={close} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AddonDialogToolbar;
