/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Item } from 'src/graphql/generated/operations';
import InfoDialogTextTrigger from './text-trigger';
import ItemInfoDialog from './dialog';
import InfoDialogIconTrigger from './icon-trigger';

interface ItemInfoDialogContainerProps {
  item: Item;
  mode: 'text' | 'icon';
}

const ItemInfoDialogContainer = function ({ item, mode }: ItemInfoDialogContainerProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {mode === 'text' && <InfoDialogTextTrigger item={item} open={() => setOpen(true)} />}
      {mode === 'icon' && <InfoDialogIconTrigger item={item} open={() => setOpen(true)} />}
      <ItemInfoDialog open={open} handleClose={handleClose} item={item} />
    </>
  );
};

export default ItemInfoDialogContainer;
