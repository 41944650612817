/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import useItemImages from 'src/hooks/item-images';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTranslation } from 'react-i18next';
import { Item } from 'src/graphql/generated/operations';
import Transition from '../dialog-transition';

interface ItemInfoDialogProps {
  item: Item;
  handleClose: () => void;
  open: boolean;
}

const ItemInfoDialog = function ({ item, handleClose, open }: ItemInfoDialogProps) {
  const { t } = useTranslation();
  const images = useItemImages(item);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      scroll="paper"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent dividers sx={{ p: 0, borderTop: 0 }}>
        <Card>
          <CardHeader
            disableTypography
            data-test-id="item-info-dialog-header"
            title={<Typography variant="body1">{item.name}</Typography>}
          />
          {item.preview && (
            <CardMedia component="img" height="220" image={images[320]} alt={item.name} />
          )}
          <CardContent>
            <Typography variant="body2">{t('common.infoDialog.description')}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ pb: 2 }}>
              {item.description}
            </Typography>
            <Typography variant="body2">{t('common.infoDialog.extra')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {item.info}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          data-test-id="item-info-dialog-closer"
          variant="contained"
          disableElevation
          onClick={handleClose}
        >
          {t('common.infoDialog.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemInfoDialog;
