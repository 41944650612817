/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { AddonItemRequestType, GroupAddonRequestType } from 'src/graphql/generated/operations';

const AddonRowPrice = function ({ addonItems }: { addonItems: AddonItemRequestType[] }) {
  const total = addonItems.map((item) => item.price).reduce((a, b) => a + b, 0);

  return <span>(+{total} kr)</span>;
};

const AddonRow = function ({ addonItems }: { addonItems: AddonItemRequestType[] }) {
  return (
    <>
      {addonItems.map((item, ind) => (
        <span key={ind}>
          <span>{item.label}</span>
          {ind + 1 < addonItems.length && <span>, </span>}
        </span>
      ))}
    </>
  );
};

const AddonRows = function ({ group, index }: { group: GroupAddonRequestType[]; index: number }) {
  const items = group.map((g) => g.addonItems).flat();
  const rowHasAddons = items.length > 0;

  return (
    <Typography variant="body2">
      <span>{index}.</span>{' '}
      {rowHasAddons ? (
        <>
          <AddonRow addonItems={items} /> <AddonRowPrice addonItems={items} />
        </>
      ) : (
        <span>Inga tillval</span>
      )}
    </Typography>
  );
};

export default AddonRows;
