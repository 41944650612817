/* eslint-disable no-underscore-dangle */
import {
  AddonItemRequestType,
  GroupAddonRequestType,
  GroupAddonType,
  Item,
} from 'src/graphql/generated/operations';

export type IFormValue = Array<string | string[]>;

export interface IFormValues {
  [key: string]: IFormValue;
}

export const validateGroup = (value: IFormValue, groupId: string, item: Item) => {
  const group = item.addons.find((g) => g._id === groupId);
  const formValue = value || '';
  const isArray = typeof formValue === 'object';
  const isString = typeof formValue === 'string';
  let selectedOptions = 0;

  if (isArray) {
    selectedOptions = formValue.length;
  }
  if (isString) {
    selectedOptions = formValue.length > 0 ? 1 : 0;
  }

  const isTooFew = !group.multiple && selectedOptions === 0;
  const isTooMany = group.multiple && !!group.maxChoices && selectedOptions > group.maxChoices;
  const isTooFewMessage = isTooFew ? 'Välj ett alternativ' : '';
  const isTooManyMessage = isTooMany ? `Välj max ${group.maxChoices} alternativ` : '';

  return isTooFewMessage || isTooManyMessage || true;
};

export const flattenFormValues = (formValues: IFormValues): string[] => {
  const addonIds: string[] = Object.values(formValues)
    .map((val) => {
      let res: string[] = [];
      if (typeof val === 'object') {
        res = val as string[];
      }
      if (typeof val === 'string') {
        res = [val];
      }
      return res;
    })
    .flat();

  return addonIds;
};

export const mapFromIdsToGroupRequest = (
  addonIds: string[],
  item: Item,
): GroupAddonRequestType[] => {
  const addonTypes = item.addons;
  const mapToAddonItemRequest = (addon: GroupAddonType): AddonItemRequestType => ({
    id: addon._id,
    label: addon.label,
    price: addon.price,
  });

  const groups: GroupAddonRequestType[] = addonTypes.map((a) => ({
    addonItems: a.group.filter((g) => addonIds.includes(g._id)).map(mapToAddonItemRequest),
    id: a._id,
    label: a.label,
  }));

  return groups;
};
