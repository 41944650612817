import { CategoryType, ItemIngestionType } from 'src/graphql/generated/operations';
import { useGlobalState } from './global-state';
import useIngestionType from './ingestion-type';
import { useSelectablePreorderTimes } from './schedules';
import { categoryIsPreorderable } from './utils';

/**
 * Returns a boolean indiciating whether the provided category is open for preorder.
 * @returns boolean
 */
export const useCategoryIsOpenForPreorder = (categoryId: number): boolean => {
  const ingestion = useIngestionType();
  const [available] = useGlobalState('availableCategories');
  const category = available.find((c) => c.category === categoryId);
  const categoryPreorderTimes = useSelectablePreorderTimes(categoryId);
  const isPreorderable = categoryIsPreorderable(category);
  const hasPreorderTimes = categoryPreorderTimes.length > 0;

  return ingestion === ItemIngestionType.Takeaway && isPreorderable && hasPreorderTimes;
};

/**
 * Returns a boolean indiciating whether the provided category is currently open.
 * This can mean that the category is either open or available for preorder.
 * @returns boolean
 */
export const useCategoryIsOpen = (categoryId: number): boolean => {
  const [available] = useGlobalState('availableCategories');
  const openForPreorder = useCategoryIsOpenForPreorder(categoryId);
  const category = available.find((c) => c.category === categoryId);

  return category.active || openForPreorder;
};

/**
 * Returns a boolean indiciating whether the current category is currently open.
 * * This can mean that the category is either open or available for preorder.
 * @returns boolean
 */
export const useCurrentCategoryIsOpen = (): boolean => {
  const [activeCategory] = useGlobalState('activeCategory');
  const openForPreorder = useCategoryIsOpenForPreorder(activeCategory.category);

  return activeCategory.active || openForPreorder;
};

/**
 * Returns a number indicating if and when the current category closes.
 * @returns number | null
 */
export const useCurrentCategoryClosesWithin = (): number | null => {
  const [activeCategory] = useGlobalState('activeCategory');

  return activeCategory.willCloseWithin;
};

/**
 * Returns a boolean indiciating whether any of the categories is open for preorder.
 * @returns boolean
 */
export const usePreorderableCategories = (): CategoryType[] => {
  const ingestion = useIngestionType();
  const [available] = useGlobalState('availableCategories');
  const preorderableCategories = available.filter(categoryIsPreorderable);

  return ingestion === ItemIngestionType.Takeaway ? preorderableCategories : [];
};
