import dayjs, { Dayjs } from 'dayjs';
import { ExceptionTime, Schedule, ScheduleTime } from 'src/graphql/generated/operations';
import { useGlobalState } from './global-state';
import { categoryIsPreorderable } from './utils';

export interface DayjsInterval {
  from: Dayjs;
  to: Dayjs;
}

const getTodaysExceptions = (schedule: Schedule): ExceptionTime[] => {
  const { exceptions } = schedule;
  const todaysExceptions = exceptions.filter((time) => dayjs(time.date).isSame(dayjs(), 'day'));
  return todaysExceptions;
};

const getTodaysSchedules = (schedule?: Schedule): ScheduleTime[] => {
  const now = dayjs();
  const day = now.day();
  let intervals: ScheduleTime[] = [];

  // If no schedule was provided, return an empty array
  if (!schedule) {
    return intervals;
  }

  // First, take the normal schedule for the current day.
  switch (day) {
    case 0:
      intervals = schedule.SUNDAY;
      break;
    case 1:
      intervals = schedule.MONDAY;
      break;
    case 2:
      intervals = schedule.TUESDAY;
      break;
    case 3:
      intervals = schedule.WEDNESDAY;
      break;
    case 4:
      intervals = schedule.THURSDAY;
      break;
    case 5:
      intervals = schedule.FRIDAY;
      break;
    case 6:
      intervals = schedule.SATURDAY;
      break;
    default:
      intervals = [];
      break;
  }
  // the schedule days are not required fields, only exceptions is.
  intervals = intervals || [];

  // Second, check if there are any exceptions for today
  const todaysExceptions = getTodaysExceptions(schedule);

  // and return those times instead, if that is the case
  return todaysExceptions.length ? todaysExceptions.map((x) => x.times).flat() : intervals;
};

const findNextOpenInterval = (schedules: ScheduleTime[]): DayjsInterval | undefined => {
  const convertScheduleToInterval = (st: ScheduleTime): DayjsInterval => {
    const fromHour = parseInt(st.from.split(':')[0], 10);
    const fromMinute = parseInt(st.from.split(':')[1], 10);
    const toHour = parseInt(st.to.split(':')[0], 10);
    const toMinute = parseInt(st.to.split(':')[1], 10);

    const from = dayjs().set('hour', fromHour).set('minute', fromMinute);
    let to = dayjs().set('hour', toHour).set('minute', toMinute);

    // If for some reason to is before from, that means that the "to" time is
    // early in the morning, such as 01:00, and that should then be the next day,
    // so we add one day to that date.
    if (to.isBefore(from)) {
      to = to.add(1, 'day');
    }

    return {
      from,
      to,
    };
  };

  const interval = schedules.find((s) => {
    const toHour = parseInt(s.to.split(':')[0], 10);
    const toMinute = parseInt(s.to.split(':')[1], 10);
    const now = dayjs();
    const to = dayjs().set('hour', toHour).set('minute', toMinute);

    return now.isBefore(to);
  });

  return interval ? convertScheduleToInterval(interval) : undefined;
};

export const useAnyCategoryActive = (): boolean => {
  const [available] = useGlobalState('availableCategories');

  return available.filter((cat) => cat.active).length > 0;
};

export const useNextPreorderHours = (categoryId?: number): DayjsInterval | undefined => {
  const [available] = useGlobalState('availableCategories');
  const preorderable = available
    .filter((c) => c.category === categoryId)
    .find(categoryIsPreorderable);
  const schedules = getTodaysSchedules(preorderable?.schedule);

  return findNextOpenInterval(schedules);
};

export const useSelectablePreorderTimes = (categoryId?: number): string[] => {
  const nextOpenHours = useNextPreorderHours(categoryId);
  const selectableTimes = [];

  if (nextOpenHours) {
    let time = nextOpenHours.from;

    while (time.add(15, 'minutes').isBefore(nextOpenHours.to)) {
      time = time.add(15, 'minutes');
      selectableTimes.push(time.format('HH:mm'));
    }
  }

  return selectableTimes;
};
