import { ItemIngestionType } from 'src/graphql/generated/operations';
import useCategoriesParameter from './categories-parameter';
import useIngestionType from './ingestion-type';
import useTableNumberLabel from './table-number-label';

const usePersistentQuery = (): string => {
  const categoriesParameter = useCategoriesParameter();
  const { queryParameter: tableLabelQueryParameter } = useTableNumberLabel();
  const ingestion = useIngestionType();
  const ingestionParam =
    ingestion === ItemIngestionType.InHouse ? 'takeaway=false' : 'takeaway=true';
  const categoriesParam =
    categoriesParameter.length > 0 ? `categories=${categoriesParameter.join(',')}` : '';
  let query = `${ingestionParam}`;
  query += categoriesParam ? `&${categoriesParam}` : '';
  query += tableLabelQueryParameter ? `&tableNumberLabel=${tableLabelQueryParameter}` : '';

  return query;
};

export default usePersistentQuery;
