/* eslint-disable react/no-array-index-key */
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { Item } from 'src/graphql/generated/operations';
import { usePaiditCart } from 'src/hooks/paidit-cart';
import AddonRows from './addon-rows';

const CartItemAddonList = function ({ item }: { item: Item }) {
  const itemHasAddonsAvailable = item.addons.flat().length > 0;
  if (!itemHasAddonsAvailable) {
    // If there are no addons on the item, return quickly to not compute a lot of item/cart info.
    return null;
  }
  const { getAddonsForItem } = usePaiditCart();
  const addonsForItem = getAddonsForItem(item.id);

  return (
    <Stack direction="column" spacing={0.2} data-test-id="cart-item-addons">
      {addonsForItem.map((group, index) => (
        <AddonRows key={index} index={index + 1} group={group} />
      ))}
    </Stack>
  );
};

export default CartItemAddonList;
