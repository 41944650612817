/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useItemImages from 'src/hooks/item-images';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Item } from 'src/graphql/generated/operations';
import Transition from '../dialog-transition';

interface ItemCommentDialogProps {
  open: boolean;
  item: Item;
  comment: string;
  onClose: () => void;
  onChange: (comment: string) => void;
  onRemove: () => void;
}

const ItemCommentDialog = function ({
  open,
  item,
  comment,
  onClose,
  onChange,
  onRemove,
}: ItemCommentDialogProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'common.comments.dialog' });
  const images = useItemImages(item);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      scroll="paper"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogContent dividers sx={{ p: 0, borderTop: 0 }}>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Typography variant="body1">
                {t('heading')} {item.name}
              </Typography>
            }
          />
          {item.preview && (
            <CardMedia component="img" height="180" image={images[320]} alt={item.name} />
          )}
          <CardContent>
            <TextField
              label={t('title')}
              multiline
              rows={5}
              value={comment}
              fullWidth
              onChange={(e) => onChange(e.target.value)}
              data-test-id="input-comment"
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRemove}>{t('removeCta')}</Button>
        <Button
          data-test-id="comment-done-cta"
          variant="contained"
          disableElevation
          onClick={onClose}
        >
          {t('cta')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemCommentDialog;
