/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  AddonType,
  GroupAddonRequestType,
  GroupAddonType,
  Item,
} from 'src/graphql/generated/operations';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { flattenFormValues, IFormValues, validateGroup, mapFromIdsToGroupRequest } from './utils';
import DialogFormHeader from './header';
import DialogFormFooter from './footer';

const AddonDialogForm = function ({
  item,
  submit,
}: {
  item: Item;
  submit: (addons: GroupAddonRequestType[]) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const internalSubmit = (formValues: IFormValues) => {
    const addonIds = flattenFormValues(formValues);
    const groupAddonRequestTypes = mapFromIdsToGroupRequest(addonIds, item);
    submit(groupAddonRequestTypes);
  };

  // We defined this component in here, memoized, to avoid sending the
  // the form reference/functions as props.
  const FormOption = React.useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ groupAddonType, addonType }: { groupAddonType: GroupAddonType; addonType: AddonType }) => {
      const addonTypeId = addonType._id;
      const hasCost = !!groupAddonType.price;
      const costSuffix = hasCost ? ` (+${groupAddonType.price} kr)` : '';
      const { onChange, onBlur, name, ref } = register(addonTypeId, {
        validate: (value) => validateGroup(value, addonTypeId, item),
      });
      return (
        <FormControl key={groupAddonType._id}>
          <FormControlLabel
            label={<Typography sx={{ ml: 1 }}>{`${groupAddonType.label}${costSuffix}`}</Typography>}
            control={
              <input
                data-test-id={`addon-option-${groupAddonType._id}`}
                id={groupAddonType._id}
                value={groupAddonType._id}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                ref={ref}
                type={addonType.multiple ? 'checkbox' : 'radio'}
              />
            }
          />
        </FormControl>
      );
    },
    [register],
  );

  return (
    <Box sx={{ mb: 8 }}>
      {item.addons.map((addonType) => {
        const addonTypeId = addonType._id;
        const errorMessage = errors[addonTypeId]?.message;
        const hasError = !!errorMessage;

        return (
          <Box key={addonTypeId} sx={{ mb: 2 }}>
            <DialogFormHeader addonType={addonType} />
            <Collapse in={hasError}>
              <Alert severity="error">{errorMessage}</Alert>
            </Collapse>
            <Stack direction="column" spacing={1} sx={{ p: 1 }}>
              {addonType.group.map((groupAddonType) => (
                <FormOption
                  key={groupAddonType._id}
                  groupAddonType={groupAddonType}
                  addonType={addonType}
                />
              ))}
            </Stack>
          </Box>
        );
      })}
      <DialogFormFooter submit={handleSubmit(internalSubmit)} />
    </Box>
  );
};

export default AddonDialogForm;
