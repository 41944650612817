import * as React from 'react';
import { AddonType } from 'src/graphql/generated/operations';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const DialogFormHeader = function ({ addonType }: { addonType: AddonType }) {
  const hasMaximum = addonType.maxChoices && addonType.maxChoices > 0;
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body1">
        <b>{addonType.label}</b>
      </Typography>

      {hasMaximum && (
        <Typography variant="body2">
          <i>{t('common.itemAddonDialog.maxChoices', { max: addonType.maxChoices })}</i>
        </Typography>
      )}
    </Box>
  );
};

export default DialogFormHeader;
